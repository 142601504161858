export const allowAnnualReportConfEditing = (email) => {
  const allowedUserEmails = ['rita@zenbusiness.com', 'ayleen@zenbusiness.com', 'brett@zenbusiness.com', 'harrison.reid@zenbusiness.com', 'harrison@zenbusiness.com'];
  return allowedUserEmails.includes(email);
};

export const extractRefunds = (refunds, chargeType) => {
  const totalRefunds = refunds.filter((refund) => refund.charge_type === chargeType).map((refund) => parseInt(refund.amount)).reduce((a, b) => a + b, 0);
  return totalRefunds;
};

export const parsePositiveNumber = (value) => {
  return value > 0 ? parseFloat(value) : 0;
}

export function parseResponseError(data) {
  // look for specific error message to show
  const error = !!data.error;
  if (error) return data.error;

  /*
  try parsing errors like this:
  {
    field1: [{error: "error_detail_1"}, {error: "error_detail_1"}]
    field2: [{error: "error_detail_1"}, {error: "error_detail_2"}]
  }
  into this:
  field1: error_detail_1, error_detail_1. field2: error_detail_1, error_detail_1
  */
  const errors = Object.entries(data).map(
    ([field, arr]) => {
      let errors = arr.map((obj) => `${obj.error}`);
      errors = errors.join(', ');
      return `${field}:\n${errors}`;
    }
  );
  return errors.join('. ');
}

export const stateObj = {
  AK: 'Alaska',
  AL: 'Alabama',
  AR: 'Arkansas',
  AS: 'American Samoa',
  AZ: 'Arizona',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DC: 'District of Columbia',
  DE: 'Delaware',
  FL: 'Florida',
  GA: 'Georgia',
  GU: 'Guam',
  HI: 'Hawaii',
  IA: 'Iowa',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  MA: 'Massachusetts',
  MD: 'Maryland',
  ME: 'Maine',
  MI: 'Michigan',
  MN: 'Minnesota',
  MO: 'Missouri',
  MP: 'Northern Mariana Islands',
  MS: 'Mississippi',
  MT: 'Montana',
  NA: 'National',
  NC: 'North Carolina',
  ND: 'North Dakota',
  NE: 'Nebraska',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NV: 'Nevada',
  NY: 'New York',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  PR: 'Puerto Rico',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VA: 'Virginia',
  VI: 'Virgin Islands',
  VT: 'Vermont',
  WA: 'Washington',
  WI: 'Wisconsin',
  WV: 'West Virginia',
  WY: 'Wyoming'
};

export function formatAddress(addressObj = {}) {
  // Initialize the address string
  let formattedAddress = addressObj?.address_1 || addressObj?.address_line1 || '';

  // Check if address2 is present; if so, append it directly without a comma
  if (addressObj?.address_2 || addressObj?.address_line2) {
    const address2 = addressObj.address_2 || addressObj.address_line2;
    formattedAddress += ` ${address2}`;
  }

  // For city, state, and zip, add them with a preceding comma if they exist and if the address isn't empty
  const additionalParts = [
    addressObj?.city,
    addressObj?.state,
    addressObj?.zip
  ].filter(part => part); // Filter out falsy values

  // Add these parts with a comma only if the address isn't empty, otherwise just concatenate
  if (additionalParts.length > 0) {
    formattedAddress += formattedAddress ? `, ${additionalParts.join(', ')}` : additionalParts.join(', ');
  }

  return formattedAddress;
}

export function formatDate(date) {
  const [year, month, day] = date.split('-');
  return `${month}/${day}/${year}`;
}