import { FILING_SUBMISSION_STATUSES, BUTTON_STATE } from '../../../assets/constants/bofFilings';

const STATUS_MAPPING = {
  [FILING_SUBMISSION_STATUSES.FILING_UPDATE_NOT_FOUND]: {
    beginButton: BUTTON_STATE.ENABLED,
    filingStatus: FILING_SUBMISSION_STATUSES.INCOMPLETE
  },
  [FILING_SUBMISSION_STATUSES.PENDING]: {
    beginButton: BUTTON_STATE.DISABLED,
    filingStatus: FILING_SUBMISSION_STATUSES.PENDING
  },
  [FILING_SUBMISSION_STATUSES.COMPLETED]: {
    beginButton: BUTTON_STATE.DISABLED,
    filingStatus: FILING_SUBMISSION_STATUSES.COMPLETED
  },
  [FILING_SUBMISSION_STATUSES.FAILED]: {
    beginButton: BUTTON_STATE.ENABLED,
    filingStatus: FILING_SUBMISSION_STATUSES.FAILED
  },
  [FILING_SUBMISSION_STATUSES.REJECTED]: {
    beginButton: BUTTON_STATE.ENABLED,
    filingStatus: FILING_SUBMISSION_STATUSES.REJECTED
  }
};

export default {
  setBofLatestFilingStatus: (state, filingStatus) => {
    const mapping = STATUS_MAPPING[filingStatus] || {};
    state.beginButton = mapping.beginButton !== undefined ? mapping.beginButton : state.beginButton;
    state.filingStatus = mapping.filingStatus || state.filingStatus;
  },
  setFilingNotes: (state, filingNotes) => {
   filingNotes !== '' ? state.filingNotes = JSON.stringify(filingNotes) : state.filingNotes = '';
  }
};
