import httpclient from '../../httpclient';
import messages from '../../../assets/messages';
import { httpRequestErrorHandling, validateParams } from '../utils';

export default {
    submitBofData({ commit }, { orderTaskUuid, businessEntityUuid, businessEntityJurisdictionUuid, formationDate }) {
        if(!validateParams.UUID(orderTaskUuid) || !validateParams.UUID(businessEntityUuid) || !validateParams.UUID(businessEntityJurisdictionUuid)) {
          httpRequestErrorHandling(new Error(messages.validationUUID.error.primary), commit, messages.validationUUID)
        } else {
          const body = {
            orderTaskUuid,
            businessEntityUuid,
            businessEntityJurisdictionUuid,
            formationDate
          }
          return httpclient.post(`/compliance/api/v1/filing/bof/submitFiling`, body)
            .then(() => {
              commit('setAlertMessage', messages.bofFilingSubmission.success, { root: true });
              commit('setBofLatestFilingStatus', 'pending');
            })
            .catch((e) => {
              const errorMessages = e.response?.data?.errorMessages || [];
  
              if (e.response.status === 400) {
                const updatedInvalidRequestError = {
                  ...messages.bofFilingSubmission.invalidRequestError,
                  secondary: errorMessages
                };
                commit('setAlertMessage', updatedInvalidRequestError, { root: true }); 
                commit('setBofLatestFilingStatus', 'failed');
              } else if (e.response.status === 422) {
                const updatedValidationRequestError = {
                  ...messages.bofFilingSubmission.validationRequestError,
                  secondary: errorMessages
                };
                commit('setAlertMessage', updatedValidationRequestError, { root: true });
                commit('setBofLatestFilingStatus', 'failed');
              } else {
                const updatedUnexpectedExceptionError = {
                  ...messages.bofFilingSubmission.unexpectedExceptionError,
                }
                commit('setAlertMessage', updatedUnexpectedExceptionError, { root: true });
                commit('setBofLatestFilingStatus', 'failed');
              }         
            });
        }
    },
    fetchFilingStatus({ commit }, { orderTaskUuid }) {
      if(!validateParams.UUID(orderTaskUuid)) {
        httpRequestErrorHandling(new Error(messages.validationUUID.error.primary), commit, messages.validationUUID)
      } else {
        return httpclient.get(`/compliance/api/v1/filing-update/filing-status/${orderTaskUuid}`)
          .then((response) => {
            commit('setBofLatestFilingStatus', response.data.newStatus);
            commit('setFilingNotes', response.data.data.error);
            return response?.data;
          })
          .catch((e) => {
            if (e?.response?.status === 404) {
              commit('setBofLatestFilingStatus', 'not_found');
            } else {
              httpRequestErrorHandling(e, commit)
            }
          });
      }
    },
}